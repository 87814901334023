import React from 'react'
import { Box, UserMenuItem, VelasIcon, Text } from 'packages/uikit'
import { useRouter } from 'next/router'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import Wrapper from './Wrapper'

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`

const NetworkWrapper = styled.div`
  align-items: center;

  background: linear-gradient(180deg, #666666 0%, #000000 100%);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.5);

  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: 0;
  width: 100%;
  height: 35px;
  padding: 10px;

  box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.49);

  &:hover:not(:disabled) {
    background-color: transparent;
  }

  &:active:not(:disabled) {
    opacity: 0.85;
    transform: translateY(1px);
  }
`

interface Chain {
  url: string
  label: string
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
}

const chains: Chain[] = [{ url: '', label: 'Hybrid', Icon: VelasIcon }]

const NetworkSelect: React.FC = () => {
  const { t } = useTranslation()
  const origin = typeof window !== 'undefined' ? window.location.origin : ''
  const selectedOne = chains.find((c) => c.url.toLowerCase() === origin.toLowerCase())

  return (
    <>
      <Box padding="10px">
        {chains.map((chain) => (
          <NetworkWrapper
            key={chain.url}
            style={{ justifyContent: 'flex-start' }}
            // onClick={() => {
            //   if (window) {
            //     window.location.replace(chain.url)
            //   }
            // }}
          >
            {chain.Icon && <chain.Icon mr="8px" />}
            <Text nowrap fontSize="12px" fontWeight="300" bold mr="8px">
              {chain.label} Testnet
            </Text>
            <Box borderRadius="50%" width="8px" height="8px" background="rgba(57, 221, 140, 1)" />
          </NetworkWrapper>
        ))}
      </Box>
    </>
  )
}

export const NetworkSwitcher: React.FC = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const origin = typeof window !== 'undefined' ? window.location.origin : ''

  return (
    <StyledBox height="100%">
      <Wrapper mr="8px" placement="bottom" variant="default" text={t(chains[0]?.label)}>
        {() => <NetworkSelect />}
      </Wrapper>
    </StyledBox>
  )
}
